var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "timetable-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Группы", to: "/timetables" },
                        { text: _vm.title }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12", lg: "11" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.title
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.timetable.status === 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.timetable.status === 0
                                        ? _c(
                                            "base-material-alert",
                                            {
                                              attrs: {
                                                color: "warning",
                                                dark: "",
                                                icon: "mdi-zip-disk"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Группа в архиве!\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _vm.id > 0
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0 pl-0",
                                              attrs: { cols: "12", md: "auto" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-1",
                                                  attrs: {
                                                    color: "secondary",
                                                    rounded: ""
                                                  },
                                                  on: {
                                                    click: _vm.createSchedule
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-plus\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    Добавить расписание в группу\n                  "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-col", {
                                            staticClass: "pt-0 pb-0"
                                          }),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: { cols: "12", md: "auto" }
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass: "mt-0 mb-0",
                                                attrs: {
                                                  label: _vm.onlyArchivedLabel
                                                },
                                                model: {
                                                  value: _vm.onlyArchived,
                                                  callback: function($$v) {
                                                    _vm.onlyArchived = $$v
                                                  },
                                                  expression: "onlyArchived"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.id > 0
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-data-table", {
                                                attrs: {
                                                  loading: _vm.loading,
                                                  "loading-text": "Загрузка...",
                                                  headers: _vm.headers,
                                                  items:
                                                    _vm.timetable.schedules,
                                                  "sort-by": ["id"],
                                                  "sort-desc": [true],
                                                  "items-per-page": -1
                                                },
                                                on: {
                                                  "click:row":
                                                    _vm.singleSchedule
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item.status",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.status
                                                            ? _c("v-icon", [
                                                                _vm._v(
                                                                  "\n                        mdi-eye\n                      "
                                                                )
                                                              ])
                                                            : _c("v-icon", [
                                                                _vm._v(
                                                                  "\n                        mdi-eye-off\n                      "
                                                                )
                                                              ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.course",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "group-chip",
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.course
                                                                      .name
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_pn",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.pn,
                                                                  item.time_pn
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_vt",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.vt,
                                                                  item.time_vt
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_sr",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.sr,
                                                                  item.time_sr
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_ch",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.ch,
                                                                  item.time_ch
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_pt",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.pt,
                                                                  item.time_pt
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_sb",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.sb,
                                                                  item.time_sb
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.time_vs",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.dayTime(
                                                                  item.vs,
                                                                  item.time_vs
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item.action",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                small: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.singleSchedule(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "\n                          mdi-pencil\n                        "
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  662760527
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Мин. возраст *",
                                              "error-messages": _vm.fieldError(
                                                "min_age"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.min_age,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "min_age",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.min_age"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Макс. возраст *",
                                              "error-messages": _vm.fieldError(
                                                "max_age"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.max_age,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "max_age",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.max_age"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Макс. учащихся в группе",
                                              "error-messages": _vm.fieldError(
                                                "max_students"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.max_students,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "max_students",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "timetable.max_students"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Сложность",
                                              "error-messages": _vm.fieldError(
                                                "difficulty"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.difficulty,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "difficulty",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.difficulty"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Кол-во занятий в курсе",
                                              "error-messages": _vm.fieldError(
                                                "lessons_count"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.timetable.lessons_count,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "lessons_count",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "timetable.lessons_count"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "Стоимость всего курса обучения (разовая оплата всех занятий)",
                                              "error-messages": _vm.fieldError(
                                                "tariff_full"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.tariff_full,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "tariff_full",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "timetable.tariff_full"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "10" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Название",
                                              "error-messages": _vm.fieldError(
                                                "description"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "timetable.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Order",
                                              "error-messages": _vm.fieldError(
                                                "order"
                                              ),
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.timetable.order,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "order",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.order"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "12" }
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Подробное описание",
                                              rows: "1",
                                              "auto-grow": "",
                                              "error-messages": _vm.fieldError(
                                                "description2"
                                              )
                                            },
                                            model: {
                                              value: _vm.timetable.description2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "description2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "timetable.description2"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "6 " }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "open"
                                              ),
                                              label: "Идет набор",
                                              "false-value": 0,
                                              "true-value": 1,
                                              messages:
                                                "Группа будет доступна для онлайн-записи на курс через сайт. Также в расписании на сайте будет пометка, что идет набор в группу. После формирования группы данную галочку необходимо снять в обязательном порядке."
                                            },
                                            model: {
                                              value: _vm.timetable.open,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "open",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.open"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "3" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "online"
                                              ),
                                              label: "Онлайн-группа",
                                              "false-value": 0,
                                              "true-value": 1,
                                              messages:
                                                "Группа для проведения занятий в онлайн формате"
                                            },
                                            model: {
                                              value: _vm.timetable.online,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "online",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.online"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "3" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "individual"
                                              ),
                                              label: "Индивидуальные занятия",
                                              "false-value": 0,
                                              "true-value": 1,
                                              messages:
                                                "Группа для проведения индивидуальных занятий"
                                            },
                                            model: {
                                              value: _vm.timetable.individual,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.timetable,
                                                  "individual",
                                                  $$v
                                                )
                                              },
                                              expression: "timetable.individual"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.timetable.online
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "8" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Ссылка-приглашение на конференцию **",
                                                  "error-messages": _vm.fieldError(
                                                    "zoom_url"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.timetable.zoom_url,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.timetable,
                                                      "zoom_url",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "timetable.zoom_url"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "4" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Пароль от конференции",
                                                  "error-messages": _vm.fieldError(
                                                    "zoom_password"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.timetable.zoom_password,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.timetable,
                                                      "zoom_password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "timetable.zoom_password"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "12" }
                                            },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  label:
                                                    "Комментарий включенный в письмо уведомление",
                                                  rows: "1",
                                                  "auto-grow": "",
                                                  "error-messages": _vm.fieldError(
                                                    "comment"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.timetable.comment,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.timetable,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "timetable.comment"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "6" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Ссылка на родительский чат группы",
                                                  "error-messages": _vm.fieldError(
                                                    "chat_link"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.timetable.chat_link,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.timetable,
                                                      "chat_link",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "timetable.chat_link"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "6" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Ссылка на статью/описание группы",
                                                  "error-messages": _vm.fieldError(
                                                    "about_link"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.timetable.about_link,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.timetable,
                                                      "about_link",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "timetable.about_link"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", lg: "12" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text--secondary"
                                                },
                                                [
                                                  _c("b", [_vm._v("**")]),
                                                  _vm._v(
                                                    " Для отправки автоматических оповещений о занятиях, необходимо указать ссылку-приглашение на\n                    конференцию, пароль (если необходимо), также можно добавить дополнительную текстовую информацию в\n                    поле комментарий (она будет включена в письмо уведомление).\n                  "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text--secondary"
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      "Аналогичная ссылка также может быть указана в свойствах расписаний данной группы. Если ссылка\n                      заполнена в расписании, то в рассылке будет указано она. Ссылка в расписание имеет приоритет над\n                      ссылкой в группе, если она заполнена."
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text--secondary"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    За час до начала занятия будет отправлено письмо-напоминание о предстоящем онлайн-занятии. Если\n                    занятие приходится на выходной день или по каким-то причинам необходимо его отменить, заранее\n                    создайте запись о занятии с его отменой. После создания такой записи все участники группы получат\n                    уведомление об отмене занятия.\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "success",
                                            "min-width": "100"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveTimetable()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Сохранить\n                "
                                          )
                                        ]
                                      ),
                                      _vm.timetable.status === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.timetable.status === 0 &&
                                      _vm.timetable.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Вернуть из архива\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.timetable.status === 0 &&
                                      _vm.timetable.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить группу?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Группа будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeTimetable(_vm.timetable.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }