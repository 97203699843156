<template>
  <v-container
    id="timetable-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Группы', to: '/timetables'}, {text: title}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
        lg="11"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="timetable.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="timetable.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Группа в архиве!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-row
                  v-if="id > 0"
                >
                  <v-col
                    cols="12"
                    md="auto"
                    class="pt-0 pb-0 pl-0"
                  >
                    <v-btn
                      class="ma-1"
                      color="secondary"
                      rounded
                      @click="createSchedule"
                    >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                      Добавить расписание в группу
                    </v-btn>
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                  />

                  <v-col
                    cols="12"
                    md="auto"
                    class="pt-0 pb-0"
                  >
                    <v-switch
                      v-model="onlyArchived"
                      class="mt-0 mb-0"
                      :label="onlyArchivedLabel"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="id > 0"
                >
                  <v-col cols="12">
                    <v-data-table
                      :loading="loading"
                      loading-text="Загрузка..."
                      :headers="headers"
                      :items="timetable.schedules"
                      :sort-by="['id']"
                      :sort-desc="[true]"
                      :items-per-page="-1"
                      @click:row="singleSchedule"
                    >
                      <template v-slot:item.status="{ item }">
                        <v-icon v-if="item.status">
                          mdi-eye
                        </v-icon>
                        <v-icon v-else>
                          mdi-eye-off
                        </v-icon>
                      </template>
                      <template v-slot:item.course="{ item }">
                        <v-chip
                          color="secondary"
                          class="group-chip"
                          small
                        >
                          {{ item.course.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item.time_pn="{ item }">
                        {{ dayTime(item.pn, item.time_pn) }}
                      </template>
                      <template v-slot:item.time_vt="{ item }">
                        {{ dayTime(item.vt, item.time_vt) }}
                      </template>
                      <template v-slot:item.time_sr="{ item }">
                        {{ dayTime(item.sr, item.time_sr) }}
                      </template>
                      <template v-slot:item.time_ch="{ item }">
                        {{ dayTime(item.ch, item.time_ch) }}
                      </template>
                      <template v-slot:item.time_pt="{ item }">
                        {{ dayTime(item.pt, item.time_pt) }}
                      </template>
                      <template v-slot:item.time_sb="{ item }">
                        {{ dayTime(item.sb, item.time_sb) }}
                      </template>
                      <template v-slot:item.time_vs="{ item }">
                        {{ dayTime(item.vs, item.time_vs) }}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-btn
                          color="primary"
                          small
                          @click.stop="singleSchedule(item)"
                        >
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.min_age"
                      label="Мин. возраст *"
                      :error-messages="fieldError('min_age')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.max_age"
                      label="Макс. возраст *"
                      :error-messages="fieldError('max_age')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.max_students"
                      label="Макс. учащихся в группе"
                      :error-messages="fieldError('max_students')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.difficulty"
                      label="Сложность"
                      :error-messages="fieldError('difficulty')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.lessons_count"
                      label="Кол-во занятий в курсе"
                      :error-messages="fieldError('lessons_count')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.tariff_full"
                      label="Стоимость всего курса обучения (разовая оплата всех занятий)"
                      :error-messages="fieldError('tariff_full')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="10"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.description"
                      label="Название"
                      :error-messages="fieldError('description')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.order"
                      label="Order"
                      :error-messages="fieldError('order')"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-textarea
                      v-model="timetable.description2"
                      label="Подробное описание"
                      rows="1"
                      auto-grow
                      :error-messages="fieldError('description2')"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6 "
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="timetable.open"
                      :error-messages="fieldError('open')"
                      label="Идет набор"
                      :false-value="0"
                      :true-value="1"
                      messages="Группа будет доступна для онлайн-записи на курс через сайт. Также в расписании на сайте будет пометка, что идет набор в группу. После формирования группы данную галочку необходимо снять в обязательном порядке."
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="timetable.online"
                      :error-messages="fieldError('online')"
                      label="Онлайн-группа"
                      :false-value="0"
                      :true-value="1"
                      messages="Группа для проведения занятий в онлайн формате"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="3"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="timetable.individual"
                      :error-messages="fieldError('individual')"
                      label="Индивидуальные занятия"
                      :false-value="0"
                      :true-value="1"
                      messages="Группа для проведения индивидуальных занятий"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="timetable.online">
                  <v-col
                    cols="12"
                    lg="8"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.zoom_url"
                      label="Ссылка-приглашение на конференцию **"
                      :error-messages="fieldError('zoom_url')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.zoom_password"
                      label="Пароль от конференции"
                      :error-messages="fieldError('zoom_password')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-textarea
                      v-model="timetable.comment"
                      label="Комментарий включенный в письмо уведомление"
                      rows="1"
                      auto-grow
                      :error-messages="fieldError('comment')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.chat_link"
                      label="Ссылка на родительский чат группы"
                      :error-messages="fieldError('chat_link')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="timetable.about_link"
                      label="Ссылка на статью/описание группы"
                      :error-messages="fieldError('about_link')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    class=""
                  >
                    <p class="text--secondary">
                      <b>**</b> Для отправки автоматических оповещений о занятиях, необходимо указать ссылку-приглашение на
                      конференцию, пароль (если необходимо), также можно добавить дополнительную текстовую информацию в
                      поле комментарий (она будет включена в письмо уведомление).
                    </p>
                    <p class="text--secondary">
                      <b>Аналогичная ссылка также может быть указана в свойствах расписаний данной группы. Если ссылка
                        заполнена в расписании, то в рассылке будет указано она. Ссылка в расписание имеет приоритет над
                        ссылкой в группе, если она заполнена.</b>
                    </p>
                    <p class="text--secondary">
                      За час до начала занятия будет отправлено письмо-напоминание о предстоящем онлайн-занятии. Если
                      занятие приходится на выходной день или по каким-то причинам необходимо его отменить, заранее
                      создайте запись о занятии с его отменой. После создания такой записи все участники группы получат
                      уведомление об отмене занятия.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="saveTimetable()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="timetable.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="timetable.status === 0 && timetable.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="timetable.status === 0 && timetable.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить группу?
        </v-card-title>

        <v-card-text>
          Группа будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeTimetable(timetable.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style scoped>
  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }
</style>

<script>
  import moment from 'moment'
  import timetablesApi from '../services/TimetablesApi'
  import coursesApi from '../services/CoursesApi'
  import teachersApi from '../services/TeachersApi'
  import roomsApi from '../services/RoomsApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },

    props: ['id'],
    data () {
      return {
        timetable: {
          id: null,
          name: null,
          schedules: [],
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
        courses: [{ id: null, name: ' - не выбрано - ' }],
        types: [
          { id: null, name: ' - не выбрано - ' },
          { id: 'en', name: 'Английский' },
          { id: 'de', name: 'Немецкий' },
          { id: 'ch', name: 'Китайский' },
          { id: 'fr', name: 'Французский' },
          { id: 'es', name: 'Испанский' },
          { id: 'ru', name: 'Русский' },
        ],
        teachers: [{ id: null, name: ' - не выбрано - ' }],
        rooms: [{ id: null, name: ' - не выбрано - ' }],
        onlyArchived: false,

        headers: [
          {
            sortable: false,
            text: '',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Расписание',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Курс',
            value: 'course',
          },
          {
            sortable: true,
            text: 'ПН',
            value: 'time_pn',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ВТ',
            value: 'time_vt',
            align: 'center',
          },
          {
            sortable: true,
            text: 'СР',
            value: 'time_sr',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ЧТ',
            value: 'time_ch',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ПТ',
            value: 'time_pt',
            align: 'center',
          },
          {
            sortable: true,
            text: 'СБ',
            value: 'time_sb',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ВС',
            value: 'time_vs',
            align: 'center',
          },
          {
            sortable: true,
            text: '',
            value: 'action',
          },
        ],
      }
    },

    computed: {
      title () {
        if (this.timetable.name && this.timetable.course) return this.timetable.name + ' | ' + this.timetable.course.name
        if (this.timetable.name) return this.timetable.name
        if (this.timetable.course) return this.timetable.course.name
        if (this.id === 'create') return 'Добавить группу по расписанию'
        return '#' + this.id
      },

      onlyArchivedLabel () {
        if (this.timetable && this.timetable.schedules && this.timetable.schedules.length > 0) {
          return 'Показать архивные расписания (' + this.timetable.schedules.filter((item) => item.status === 0).length + ')'
        }
        return 'Показать архивные расписания'
      },

    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      coursesApi
        .fetchCourses()
        .then(response => {
          this.courses = this.courses.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      teachersApi
        .fetchActiveTeachers()
        .then(response => {
          this.teachers = this.teachers.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      roomsApi
        .fetchRooms()
        .then(response => {
          this.rooms = this.rooms.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.timetable = {
            id: null,
            name: null,
            status: 1,
          }
        } else {
          timetablesApi
            .fetchTimetable(this.id)
            .then(response => {
              this.timetable = response
              this.loading = false
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      saveTimetable () {
        if (this.timetable.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          timetablesApi
            .updateTimetable(this.timetable)
            .then(response => {
              this.timetable = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          timetablesApi
            .createTimetable(this.timetable)
            .then(response => {
              this.timetable = response
              this.$router.push('/timetable/' + this.timetable.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      removeTimetable () {
        this.confirmDialog = false
        this.loading = true
        timetablesApi
          .deleteTimetable(this.timetable.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      archive () {
        this.timetable.status = 0
        this.saveTimetable()
      },
      unarchive () {
        this.timetable.status = 1
        this.saveTimetable()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
      singleSchedule (item) {
        this.$router.push('/schedule/' + this.id + '/' + item.id)
      },
      createSchedule () {
        this.$router.push('/schedule/' + this.id + '/create')
      },
      dayTime (dayIsActive, time) {
        if (dayIsActive && time) {
          return time.substring(0, 5)
        }
        return '-'
      },
    },
  }
</script>
